if (/MSIE|Trident\//gi.test(navigator.userAgent)) {
	const IECard = document.createElement('div');
	IECard.className = 'ie-card';

	const cardTitle = document.createElement('div');
	cardTitle.className = 'ie-title';
	cardTitle.textContent = 'Your browser is not supported';

	const cardBody = document.createElement('div');
	cardBody.className = 'ie-body';
	cardBody.textContent = 'Use Google Chrome for an optimal experience.';

	IECard.appendChild(cardTitle);
	IECard.appendChild(cardBody);
	document.body.appendChild(IECard);
}
